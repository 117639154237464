.step020-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.step020-contentframe {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  z-index: 20;
  align-items: center;
  padding-top: 56px;
  padding-left: 0px;
  flex-direction: column;
  justify-content: center;
}
.step020-contentcontainer {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.step020-homepanel {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.step020-text {
  color: var(--dl-color-custom-postoffice);
  width: 100%;
  font-size: 22px;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 700;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
}
.step020-container01 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.step020-container02 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-color: rgba(217, 217, 217, 0.5);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
}
.step020-text01 {
  color: var(--dl-color-custom-helloblue);
  width: 100%;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.step020-icon {
  fill: var(--dl-color-custom-krakenlogin);
  width: 25px;
    height: 25px;
    max-width: 25px;
    min-width: 25px;
    max-height: 25px;
    min-height: 25px;
}
.step020-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-color: rgba(217, 217, 217, 0.5);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
}
.step020-text02 {
  color: var(--dl-color-custom-helloblue);
  width: 100%;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.step020-icon02 {
  fill: var(--dl-color-custom-krakenlogin);
  width: 25px;
  height: 25px;
  max-width: 25px;
  min-width: 25px;
  max-height: 25px;
  min-height: 25px;
}
.step020-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-color: rgba(217, 217, 217, 0.5);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
}
.step020-text03 {
  color: var(--dl-color-custom-helloblue);
  width: 100%;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.step020-icon04 {
  fill: var(--dl-color-custom-krakenlogin);
  width: 25px;
    height: 25px;
    max-width: 25px;
    min-width: 25px;
    max-height: 25px;
    min-height: 25px;
}
.step020-container05 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-color: rgba(217, 217, 217, 0.5);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
}
.step020-text04 {
  color: var(--dl-color-custom-helloblue);
  width: 100%;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.step020-icon06 {
  fill: var(--dl-color-custom-krakenlogin);
  width: 25px;
    height: 25px;
    max-width: 25px;
    min-width: 25px;
    max-height: 25px;
    min-height: 25px;
}
.step020-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-color: rgba(217, 217, 217, 0.5);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
}
.step020-text05 {
  color: var(--dl-color-custom-helloblue);
  width: 100%;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.step020-icon08 {
  fill: var(--dl-color-custom-krakenlogin);
  width: 25px;
    height: 25px;
    max-width: 25px;
    min-width: 25px;
    max-height: 25px;
    min-height: 25px;
}
.step020-container07 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-color: rgba(217, 217, 217, 0.5);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
}
.step020-text06 {
  color: var(--dl-color-custom-helloblue);
  width: 100%;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.step020-icon10 {
  fill: var(--dl-color-custom-krakenlogin);
  width: 25px;
    height: 25px;
    max-width: 25px;
    min-width: 25px;
    max-height: 25px;
    min-height: 25px;
}
.step020-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-color: rgba(217, 217, 217, 0.5);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
}
.step020-text07 {
  color: var(--dl-color-custom-helloblue);
  width: 100%;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.step020-icon12 {
  fill: var(--dl-color-custom-krakenlogin);
  width: 25px;
    height: 25px;
    max-width: 25px;
    min-width: 25px;
    max-height: 25px;
    min-height: 25px;
}
.step020-container09 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-color: rgba(217, 217, 217, 0.5);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
}
.step020-text08 {
  color: var(--dl-color-custom-helloblue);
  width: 100%;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.step020-icon14 {
  fill: var(--dl-color-custom-krakenlogin);
  width: 25px;
    height: 25px;
    max-width: 25px;
    min-width: 25px;
    max-height: 25px;
    min-height: 25px;
}
.step020-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-color: rgba(217, 217, 217, 0.5);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
}
.step020-text09 {
  color: var(--dl-color-custom-helloblue);
  width: 100%;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.step020-icon16 {
  fill: var(--dl-color-custom-krakenlogin);
  width: 25px;
    height: 25px;
    max-width: 25px;
    min-width: 25px;
    max-height: 25px;
    min-height: 25px;
}
.step020-container11 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  justify-content: center;
}
.step020-navlink {
  color: var(--dl-color-gray-white);
  width: 90%;
  height: auto;
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  border-color: var(--dl-color-custom-postoffice);
  border-radius: var(--dl-radius-radius-radius16);
  text-decoration: none;
  background-color: var(--dl-color-custom-postoffice);
}
@media(max-width: 991px) {
  .step020-homepanel {
    max-width: 100%;
  }
}
@media(max-width: 767px) {
  .step020-homepanel {
    max-width: 100%;
  }
}

.businessselector-container {
    top: 0;
    left: 0;
    width: 30%;
    height: 100%;
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    z-index: 1000;
    position: fixed;
    max-width: 500px;
    min-width: 300px;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.13);
    max-height: 100%;
    min-height: 100%;
    align-items: flex-end;
    align-content: 0;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--dl-color-gray-white);
}

.businessselector-nav {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    max-height: 100%;
    min-height: 100%;
    align-items: flex-end;
    flex-direction: column;
    justify-content: space-between;
}

.businessselector-closeicon {
    width: 24px;
    height: 24px;
    align-self: flex-start;
    margin-bottom: var(--dl-space-space-unit);
}

.businessselector-extendercontainer {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

@media(max-width: 479px) {
    .businessselector-container {
        width: 100%;
        height: 100%;
        max-height: 100%;
        min-height: 100%;
    }

    .businessselector-nav {
        height: auto;
    }

    .businessselector-extendercontainer {
        flex: 1;
        height: 100%;
    }
}
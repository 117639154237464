.business-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.business-contentframe {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  z-index: 20;
  align-items: center;
  padding-top: 56px;
  padding-left: 0px;
  flex-direction: column;
  justify-content: center;
}

.business-contentcontainer {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.business-homepanel {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

@media(max-width: 991px) {
  .business-homepanel {
    max-width: 100%;
  }
}

@media(max-width: 767px) {
  .business-homepanel {
    max-width: 100%;
  }
}



.registar-camerafixer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.registar-headlinecamerafixer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.registar-camerafixertext {
  color: var(--dl-color-gray-700);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-left: var(--dl-space-space-unit);
}

.registar-openicon {
  fill: var(--dl-color-gray-700);
  width: 24px;
  height: 24px;
  max-width: 24px;
  min-width: 24px;
  max-height: 24px;
  min-height: 24px;
  margin-left: var(--dl-space-space-unit);
}

.registar-closeicon {
  fill: var(--dl-color-gray-700);
  width: 24px;
  height: 24px;
  max-width: 24px;
  min-width: 24px;
  max-height: 24px;
  min-height: 24px;
  margin-left: var(--dl-space-space-unit);
}

.registar-fixcameracollapsible {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.registar-fixcamerarefreshcontainer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: space-between;
}

.registar-fixcamerarefreshtext {
  color: var(--dl-color-gray-700);
  font-size: 18px;
}

.registar-refreshfixcamera {
  color: var(--dl-color-custom-redred);
  width: 120px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  border-color: var(--dl-color-custom-redred);
}

.registar-fixcameraswitchcontainer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: space-between;
}

.registar-fixcameraswitchtext {
  color: var(--dl-color-gray-700);
  font-size: 18px;
}

.registar-switchfixedcamera {
  color: var(--dl-color-custom-redred);
  width: 120px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  border-color: var(--dl-color-custom-redred);
}

.registar-fixcameralegacycontainer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: space-between;
}

.registar-fixcameralegacytext {
  color: var(--dl-color-gray-700);
  font-size: 18px;
}

.registar-legacyfixcamera {
  color: var(--dl-color-custom-redred);
  width: 120px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  border-color: var(--dl-color-custom-redred);
}

.registar-fixcameraentercontainer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}

.registar-fixcameraentertext {
  color: var(--dl-color-gray-700);
  font-size: 18px;
}

.registar-fixcameraenterform {
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  justify-content: space-between;
}

.registar-fixcameraenterinput {
  color: var(--dl-color-gray-500);
  width: 100%;
  border-color: var(--dl-color-gray-700);
  border-radius: var(--dl-radius-radius-radius8);
}

.registar-sendfixcamera {
  fill: var(--dl-color-custom-redred);
  width: 30px;
  height: 30px;
  max-width: 30px;
  min-width: 30px;
  max-height: 30px;
  min-height: 30px;
  margin-left: var(--dl-space-space-unit);
}

.registar-fixcamerauploadcontainer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}

.registar-fixcamerauploadtext {
  color: var(--dl-color-gray-700);
  font-size: 18px;
}

.registar-fixcamerauploadform {
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  justify-content: space-between;
}

.registar-fixcamerauploadinput {
  color: var(--dl-color-gray-500);
  width: 100%;
  border-color: var(--dl-color-gray-700);
  border-radius: var(--dl-radius-radius-radius8);
}

.registar-uploadfixcamera {
  fill: var(--dl-color-custom-redred);
  width: 30px;
  height: 30px;
  max-width: 30px;
  min-width: 30px;
  max-height: 30px;
  min-height: 30px;
  margin-left: var(--dl-space-space-unit);
}

.registar-fixcameraexplanationcontainer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
  align-items: center;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  justify-content: flex-start;
}

.registar-fixcameraexplanationtext {
  color: var(--dl-color-gray-700);
}

.registar-historyorregistration {
  color: var(--dl-color-gray-900);
  width: 100%;
  font-size: 24px;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  text-align: center;
  font-weight: 700;
}

@media(max-width: 479px) {
  .registar-fixcamerarefreshcontainer {
    align-items: flex-start;
    flex-direction: column;
  }

  .registar-refreshfixcamera {
    align-self: flex-end;
    margin-top: var(--dl-space-space-halfunit);
  }

  .registar-fixcameraswitchcontainer {
    align-items: flex-start;
    flex-direction: column;
  }

  .registar-switchfixedcamera {
    align-self: flex-end;
    margin-top: var(--dl-space-space-halfunit);
  }

  .registar-fixcameralegacycontainer {
    align-items: flex-start;
    flex-direction: column;
  }

  .registar-legacyfixcamera {
    align-self: flex-end;
    margin-top: var(--dl-space-space-halfunit);
  }

  .registar-fixcamerauploadtext {
    color: var(--dl-color-gray-700);
    font-size: 18px;
  }
}
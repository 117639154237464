.teameditor-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.teameditor-contentframe {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    z-index: 20;
    align-items: center;
    padding-top: 56px;
    padding-left: 0px;
    flex-direction: column;
    justify-content: center;
}

.teameditor-contentcontainer {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.teameditor-homepanel {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    max-width: 600px;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

@media(max-width: 991px) {
    .teameditor-homepanel {
        max-width: 100%;
    }
}

@media(max-width: 767px) {
    .teameditor-homepanel {
        max-width: 100%;
    }
}
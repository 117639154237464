.scanforbalance-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.scanforbalance-contentframe {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    z-index: 20;
    align-items: center;
    padding-left: 0px;
    flex-direction: column;
    justify-content: center;
}

.scanforbalance-contentcontainer {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.scanforbalance-homepanel {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    max-width: 600px;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.scanforbalance-container1 {
    flex: 0 0 auto;
    width: 400px;
    height: 400px;
    display: flex;
    padding: var(--dl-space-space-unit);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.08);
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    border-radius: var(--dl-radius-radius-radius24);
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgba(0, 152, 153, 0.3);
}

.scanforbalance-text {
    color: var(--dl-color-custom-postoffice);
    width: 100%;
    font-size: 22px;
    font-style: normal;
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    font-weight: 700;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
}

.scanforbalance-balanceamount {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-fourunits);
    justify-content: center;
}

.scanforbalance-text1 {
    color: var(--dl-color-custom-postoffice);
    width: 100%;
    font-size: 22px;
    font-style: normal;
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-twounits);
}

.scanforbalance-text4 {
    color: var(--dl-color-custom-redred);
    width: 100%;
    font-size: 26px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-fourunits);
}

@media(max-width: 991px) {
    .scanforbalance-homepanel {
        max-width: 100%;
    }

    .scanforbalance-container1 {
        width: 60%;
    }
}

@media(max-width: 767px) {
    .scanforbalance-homepanel {
        max-width: 100%;
    }
}

@media(max-width: 479px) {
    .scanforbalance-container1 {
        width: 95%;
    }
}
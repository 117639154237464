.settings-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.settings-contentframe {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  z-index: 20;
  align-items: center;
  padding-top: 56px;
  padding-left: 0px;
  flex-direction: column;
  justify-content: center;
}
.settings-contentcontainer {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.settings-homepanel {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.settings-form {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.settings-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.settings-icon {
  fill: var(--dl-color-custom-postoffice);
  width: 30px;
  height: 30px;
  max-width: 30px;
  min-width: 30px;
  align-self: center;
  max-height: 30px;
  min-height: 30px;
  margin-left: var(--dl-space-space-halfunit);
}
.settings-text {
  color: var(--dl-color-custom-postoffice);
  width: 100%;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
}
.settings-textinput {
  color: var(--dl-color-gray-500);
  width: 95%;
  outline: none;
  align-self: center;
  margin-top: var(--dl-space-space-oneandhalfunits);
  border-color: rgba(217, 217, 217, 0.5);
}
.settings-textinput1 {
  color: var(--dl-color-gray-500);
  width: 95%;
  outline: none;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  border-color: rgba(217, 217, 217, 0.5);
}
.settings-select {
  width: 95%;
  height: 40px;
  background: url("#ffffff");
  margin-top: var(--dl-space-space-unit);
  border-color: rgba(217, 217, 217, 0.5);
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius16);
  padding-right: var(--dl-space-space-unit);
}
.settings-select1 {
  width: 95%;
  height: 40px;
  background: url("#ffffff");
  margin-top: var(--dl-space-space-unit);
  border-color: rgba(217, 217, 217, 0.5);
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius16);
  padding-right: var(--dl-space-space-unit);
}
.settings-textarea {
  width: 95%;
  height: var(--dl-size-size-medium);
  resize: none;
  outline: none;
  padding: var(--dl-space-space-halfunit);
  margin-top: var(--dl-space-space-unit);
  border-color: rgba(217, 217, 217, 0.5);
  border-width: 1px;
}
.settings-container2 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  justify-content: flex-start;
}
.settings-icon2 {
  fill: var(--dl-color-custom-postoffice);
  width: 30px;
  height: 30px;
  max-width: 30px;
  min-width: 30px;
  max-height: 30px;
  min-height: 30px;
  margin-left: var(--dl-space-space-halfunit);
}
.settings-text1 {
  color: var(--dl-color-custom-postoffice);
  width: 100%;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
}
.settings-select2 {
  width: 95%;
  height: 40px;
  background: url("#ffffff");
  margin-top: var(--dl-space-space-oneandhalfunits);
  border-color: rgba(217, 217, 217, 0.5);
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius16);
  padding-right: var(--dl-space-space-unit);
}
.settings-textinput2 {
  color: var(--dl-color-gray-500);
  width: 95%;
  outline: none;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  border-color: rgba(217, 217, 217, 0.5);
}
.settings-textinput3 {
  color: var(--dl-color-gray-500);
  width: 95%;
  outline: none;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  border-color: rgba(217, 217, 217, 0.5);
}
.settings-textinput4 {
  color: var(--dl-color-gray-500);
  width: 95%;
  outline: none;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  border-color: rgba(217, 217, 217, 0.5);
}
.settings-textinput5 {
  color: var(--dl-color-gray-500);
  width: 95%;
  outline: none;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  border-color: rgba(217, 217, 217, 0.5);
}
.settings-container3 {
  flex: 0 0 auto;
  width: 95%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  margin-top: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  justify-content: flex-end;
}
.settings-button {
  color: var(--dl-color-gray-white);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.15);
  font-style: normal;
  font-weight: 800;
  border-color: var(--dl-color-custom-postoffice);
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-custom-postoffice);
}
@media(max-width: 991px) {
  .settings-homepanel {
    max-width: 100%;
  }
}
@media(max-width: 767px) {
  .settings-homepanel {
    max-width: 100%;
  }
}
@media(max-width: 479px) {
  .settings-form {
    width: 100%;
  }
}

.successmessage-errormessage {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.successmessage-icon {
  fill: var(--dl-color-custom-krakenlogin);
  width: var(--dl-size-size-xxlarge);
  height: var(--dl-size-size-xxlarge);
}
.successmessage-text {
  color: var(--dl-color-custom-krakenlogin);
  width: 100%;
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 700;
}
.successmessage-container {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  min-height: 100px;
  align-items: flex-start;
  flex-direction: column;
}
.successmessage-navlink {
  color: var(--dl-color-custom-krakenlogin);
  border-color: var(--dl-color-custom-krakenlogin);
  text-decoration: none;
}


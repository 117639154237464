.startsellingwidget-startsellingwidget {
    flex: 0 0 auto;
    width: 80%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-unit);
    position: relative;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.08);
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    border-radius: var(--dl-radius-radius-radius24);
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgba(0, 152, 153, 0.3);
}

.startsellingwidget-text {
    color: var(--dl-color-gray-white);
    width: 100%;
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    text-transform: capitalize;
}

.startsellingwidget-textinput {
    color: var(--dl-color-gray-500);
    width: 100%;
    height: var(--dl-size-size-small);
    margin-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius16);
}

.startsellingwidget-navlink {
    color: var(--dl-color-gray-white);
    width: 100%;
    font-style: normal;
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    font-weight: 800;
    border-color: var(--dl-color-custom-postoffice);
    border-radius: var(--dl-radius-radius-radius16);
    text-decoration: none;
    background-color: var(--dl-color-custom-postoffice);
}

@media(max-width: 991px) {
    .startsellingwidget-startsellingwidget {
        width: 60%;
    }
}

@media(max-width: 479px) {
    .startsellingwidget-startsellingwidget {
        width: 95%;
    }
}
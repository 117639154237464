.avatarlistitemwithadder-applicationitem {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
}
.avatarlistitemwithadder-image {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  min-width: var(--dl-size-size-small);
  min-height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.avatarlistitemwithadder-text {
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  padding-left: var(--dl-space-space-unit);
}
.avatarlistitemwithadder-icon {
  fill: var(--dl-color-gray-900);
  width: 30px;
  height: 30px;
  max-width: 30px;
  min-width: 30px;
  max-height: 30px;
  min-height: 30px;
}






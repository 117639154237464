.step030-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.step030-contentframe {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  z-index: 20;
  align-items: center;
  padding-top: 56px;
  padding-left: 0px;
  flex-direction: column;
  justify-content: center;
}
.step030-contentcontainer {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.step030-homepanel {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.step030-text {
  color: var(--dl-color-custom-postoffice);
  width: 100%;
  font-size: 22px;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 700;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
}
.step030-container1 {
  width: 100%;
  height: var(--dl-size-size-large);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.step030-text5 {
  color: var(--dl-color-custom-redred);
  width: 100%;
  height: auto;
  font-size: 34px;
  font-style: normal;
  text-align: center;
  font-weight: 900;
}
.step030-container2 {
  flex: 0 0 auto;
  width: 80%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.08);
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgba(0, 152, 153, 0.3);
}
.step030-text6 {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 28px;
  font-style: normal;
  text-align: center;
  font-weight: 800;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  text-transform: capitalize;
}
.step030-textinput {
  color: var(--dl-color-gray-500);
  width: 100%;
  height: var(--dl-size-size-small);
  margin-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-900);
  border-radius: var(--dl-radius-radius-radius16);
}
.step030-navlink {
  color: var(--dl-color-gray-white);
  width: 100%;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  font-weight: 800;
  border-color: var(--dl-color-custom-newsalmon);
  border-radius: var(--dl-radius-radius-radius16);
  text-decoration: none;
  background-color: var(--dl-color-custom-newsalmon);
}
@media(max-width: 991px) {
  .step030-homepanel {
    max-width: 100%;
  }
  .step030-container2 {
    width: 60%;
  }
}
@media(max-width: 767px) {
  .step030-homepanel {
    max-width: 100%;
  }
}
@media(max-width: 479px) {
  .step030-container2 {
    width: 95%;
  }
}

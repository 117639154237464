.info-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.info-contentframe {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  z-index: 20;
  align-items: center;
  padding-top: 56px;
  padding-left: 0px;
  flex-direction: column;
  justify-content: center;
}
.info-contentcontainer {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.info-homepanel {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  max-width: 600px;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: flex-start;
}
.info-info {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.info-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.info-text {
  color: var(--dl-color-custom-postoffice);
  width: 100%;
  font-size: 26px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-twounits);
}
@media(max-width: 991px) {
  .info-homepanel {
    max-width: 100%;
  }
}
@media(max-width: 767px) {
  .info-homepanel {
    max-width: 100%;
  }
}
@media(max-width: 479px) {
  .info-info {
    width: 100%;
  }
}

.step001-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.step001-contentframe {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  z-index: 20;
  align-items: center;
  padding-top: 56px;
  padding-left: 0px;
  flex-direction: column;
  justify-content: center;
}
.step001-contentcontainer {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.step001-homepanel {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.step001-container1 {
  flex: 0 0 auto;
  width: 400px;
  height: 400px;
  display: flex;
  padding: var(--dl-space-space-unit);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.08);
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgba(0, 152, 153, 0.3);
}
.step001-text {
  color: var(--dl-color-custom-postoffice);
  width: 100%;
  font-size: 22px;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  font-weight: 700;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
}
.step001-navlink {
  width: 55px;
  bottom: 7%;
  height: 55px;
  display: flex;
  position: absolute;
  max-width: 55px;
  min-width: 55px;
  max-height: 55px;
  min-height: 55px;
  align-items: center;
  border-color: var(--dl-color-custom-postoffice);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-custom-postoffice);
}
.step001-icon {
  fill: var(--dl-color-gray-white);
  width: 30px;
  height: 30px;
  max-width: 30px;
  min-width: 30px;
  align-self: center;
  max-height: 30px;
  min-height: 30px;
}

.s1-container {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  min-width: auto;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.15);
  min-height: auto;
  align-items: center;
  border-color: var(--dl-color-custom-postoffice);
  border-style: solid;
  border-width: 2px;
  justify-content: center;
  object-fit: fill;
}



@media(max-width: 991px) {
  .step001-homepanel {
    max-width: 100%;
  }
  .step001-container1 {
    width: 60%;
  }
}
@media(max-width: 767px) {
  .step001-homepanel {
    max-width: 100%;
  }
}
@media(max-width: 479px) {
  .step001-container1 {
    width: 95%;
  }
}

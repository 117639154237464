.businessname-listitem-businessname-listitem {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-unit);
    position: relative;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: rgba(217, 217, 217, 0.5);
    border-width: 1px;
    margin-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
    cursor: pointer;
}

.businessname-listitem-text {
    color: var(--dl-color-custom-postoffice);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}
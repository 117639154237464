.ledgeritempositive-ledgeritempositive {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    position: relative;
    align-items: flex-start;
    border-color: rgba(217, 217, 217, 0.5);
    border-width: 1px;
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.ledgeritempositive-typetext {
    color: var(--dl-color-custom-krakenlogin);
    width: 100%;
    font-size: 16px;
    font-style: normal;
    text-align: left;
    font-weight: 500;
    text-transform: uppercase;
}

.ledgeritempositive-nametext {
    color: var(--dl-color-gray-700);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    margin-top: 4px;
    text-align: left;
    font-weight: 500;
}

.ledgeritempositive-amounttext {
    color: var(--dl-color-custom-krakenlogin);
    width: 100%;
    font-size: 24px;
    font-style: normal;
    margin-top: 0px;
    text-align: right;
    font-weight: 700;
    text-transform: uppercase;
}

.ledgeritempositive-bottomcont {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
}

.ledgeritempositive-bytext {
    color: var(--dl-color-gray-700);
    width: 100%;
    font-size: 12px;
    font-style: normal;
    margin-top: 0px;
    text-align: left;
    font-weight: 400;
}

.ledgeritempositive-datetext {
    color: var(--dl-color-gray-700);
    width: 100%;
    font-size: 12px;
    font-style: normal;
    margin-top: 0px;
    text-align: right;
    font-weight: 400;
}
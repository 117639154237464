.header-header {
  flex: 0 0 auto;
  width: 100%;
  height: 56px;
  display: flex;
  z-index: 100;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  background-color: var(--dl-color-gray-white);
}

.lost_box {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}

.header-icon {
  fill: var(--dl-color-custom-postoffice);
  width: 30px;
  height: 40px;
  max-width: 30px;
  min-width: 30px;
  max-height: 40px;
  min-height: 40px;
  margin-left: var(--dl-space-space-halfunit);
}
.header-navlink {
  color: var(--dl-color-custom-postoffice);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  margin-left: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.header-icon2 {
  fill: rgba(0, 152, 153, 0.5);
  width: 30px;
  height: 30px;
  max-width: 30px;
  min-width: 30px;
  max-height: 30px;
  min-height: 30px;
  margin-left: var(--dl-space-space-halfunit);
}
.header-container {
  flex: 1;
  width: auto;
  height: var(--dl-size-size-xsmall);
  display: flex;
  align-items: flex-start;
}
.header-container1 {
  flex: 0 0 auto;
  width: 72px;
  height: 42px;
  display: flex;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.15);
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  margin-right: var(--dl-space-space-unit);
  padding-left: 4px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 4px;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.85);
}

.header-image {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  object-fit: cover;
  margin-right: 0px;
  border-radius: var(--dl-radius-radius-round);
}

.header-container1:hover {
  border-radius: var(--dl-radius-radius-radius16);
}
.header-icon4 {
  fill: var(--dl-color-gray-700);
  width: 24px;
  height: 24px;
  transition: 0.3s;
}
.header-icon4:hover {
  fill: var(--dl-color-bravhur-bravhur_color);
}
.header-icon6 {
  fill: var(--dl-color-custom-newsalmon);
  width: 30px;
  height: 30px;
  max-width: 30px;
  min-width: 30px;
  max-height: 30px;
  min-height: 30px;
}
.header-root-class-name {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.header-root-class-name1 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.header-root-class-name2 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.header-root-class-name3 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.header-root-class-name4 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.header-root-class-name5 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.header-root-class-name6 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.header-root-class-name7 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.header-root-class-name8 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.header-root-class-name9 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.header-root-class-name10 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.header-root-class-name11 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.header-root-class-name12 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.header-root-class-name13 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.header-root-class-name14 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.header-root-class-name15 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.header-root-class-name16 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.header-root-class-name17 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.header-root-class-name18 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.header-root-class-name19 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.header-root-class-name20 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.header-root-class-name21 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.header-root-class-name22 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.header-root-class-name23 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.header-root-class-name24 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.header-root-class-name25 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.header-root-class-name26 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}
@media(max-width: 767px) {
  .header-navlink {
    font-size: 24px;
  }
}
@media(max-width: 479px) {
  .header-navlink {
    font-size: 22px;
  }
}

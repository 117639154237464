.mainscreenwidget-mainscreenwidget {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.mainscreenwidget-historytext {
    color: var(--dl-color-gray-900);
    width: 100%;
    font-size: 22px;
    font-style: normal;
    margin-top: var(--dl-space-space-unit);
    font-weight: 700;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
}

.mainscreenwidget-historiccontainer {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    flex-direction: column;
    justify-content: flex-start;
}
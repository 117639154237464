.team-container {
    width: 100%;
    display: flex;
    background-color: var(--dl-color-gray-white);
}

.team-team {
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.team-container1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.team-icon {
    fill: var(--dl-color-custom-postoffice);
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
    margin-left: var(--dl-space-space-halfunit);
}

.team-text {
    color: var(--dl-color-custom-postoffice);
    width: 100%;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
}

.team-textinput {
    color: var(--dl-color-gray-500);
    width: 95%;
    outline: none;
    align-self: center;
    margin-top: var(--dl-space-space-unit);
    border-color: rgba(217, 217, 217, 0.5);
    border-radius: var(--dl-radius-radius-radius16);
}

.team-searchbox {
    width: 95%;
    display: flex;
    align-self: center;
    margin-top: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.team-managerlist {
    width: 95%;
    display: flex;
    align-self: center;
    margin-top: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.team-root-class-name {
    width: 100%;
}

@media(max-width: 479px) {
    .team-team {
        width: 100%;
    }
}
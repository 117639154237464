.histsuccess-histsuccess {
    flex: 0 0 auto;
    width: 80%;
    height: auto;
    display: flex;
    position: relative;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.02);
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    border-color: rgba(217, 217, 217, 0.5);
    border-width: 1px;
    padding-left: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius24);
    margin-bottom: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.histsuccess-text {
    color: var(--dl-color-custom-postoffice);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}

.histsuccess-text1 {
    color: var(--dl-color-gray-700);
    width: 100%;
    font-size: 12px;
    font-style: normal;
    margin-top: 2px;
    text-align: left;
    font-weight: 400;
}

.histsuccess-text2 {
    color: var(--dl-color-new_category-blueish);
    width: 100%;
    font-size: 24px;
    font-style: normal;
    text-align: right;
    font-weight: 900;
}

.histsuccess-container {
    flex: 0 0 auto;
    width: 100%;
    height: 30px;
    display: flex;
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
    border-radius: var(--dl-radius-radius-radius16);
    justify-content: center;
    background-color: rgba(0, 140, 114, 0.35);
}

.histsuccess-text3 {
    color: var(--dl-color-gray-white);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
}

@media(max-width: 991px) {
    .histsuccess-histsuccess {
        width: 60%;
    }
}

@media(max-width: 479px) {
    .histsuccess-histsuccess {
        width: 95%;
    }
}
.managementstrip-managementstrip {
  flex: 0 0 auto;
  width: 100%;
  height: var(--dl-size-size-small);
  display: flex;
  position: relative;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  justify-content: flex-end;
  background-color: var(--dl-color-gray-white);
}

.managementstrip-wallet-icon {
  fill: #D9D9D9;
  width: 40px;
  height: 40px;
  max-width: 40px;
  min-width: 40px;
  max-height: 40px;
  min-height: 40px;
}

.managementstrip-navlink {
  display: contents;
}
.managementstrip-icon {
  fill: var(--dl-color-gray-900);
  width: 40px;
  height: 40px;
  max-width: 40px;
  min-width: 40px;
  max-height: 40px;
  min-height: 40px;
  text-decoration: none;
  margin-left: var(--dl-space-space-unit);
}
.managementstrip-navlink1 {
  display: contents;
}
.managementstrip-icon2 {
  fill: var(--dl-color-gray-900);
  width: 40px;
  height: 40px;
  max-width: 40px;
  min-width: 40px;
  max-height: 40px;
  min-height: 40px;
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.managementstrip-navlink2 {
  display: contents;
}
.managementstrip-icon4 {
  fill: var(--dl-color-gray-900);
  width: 40px;
  height: 40px;
  max-width: 40px;
  min-width: 40px;
  max-height: 40px;
  min-height: 40px;
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.managementstrip-navlink3 {
  display: contents;
}
.managementstrip-icon6 {
  fill: var(--dl-color-gray-900);
  width: 40px;
  height: 40px;
  max-width: 40px;
  min-width: 40px;
  max-height: 40px;
  min-height: 40px;
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}




@media(max-width: 479px) {
  .managementstrip-managementstrip {
    background-color: var(--dl-color-gray-white);
  }
  .managementstrip-icon {
    fill: var(--dl-color-gray-900);
    width: 40px;
    height: 40px;
    max-width: 40px;
    min-width: 40px;
    max-height: 40px;
    min-height: 40px;
  }
  .managementstrip-icon2 {
    max-width: 40px;
    min-width: 40px;
    max-height: 40px;
    min-height: 40px;
  }
  .managementstrip-icon4 {
    max-width: 40px;
    min-width: 40px;
    max-height: 40px;
    min-height: 40px;
  }
  .managementstrip-icon6 {
    max-width: 40px;
    min-width: 40px;
    max-height: 40px;
    min-height: 40px;
  }
}
